<template>
	<div class="app-container">
		<dd-card class="box-card" :title="'Documentatie Integrare Applicatie'">
			<Box :type="'info'" :active="true">
				<div slot="box-body">
					<ul class="rawUl">
						<li><strong>Aceasta este o documentatie pentru Aplicatia de sincronizare produse dintre
								magazinul dumneavoastra online si Cel Marketplace</strong>
						</li>
					</ul>
					<iframe width="100%" height="400px" src="https://www.youtube.com/embed/8bYeOJayHho" frameborder="0"
						allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>

					</iframe>
				</div>
			</Box>
			<el-button type="success" size="small" @click="downloadApp" class="fullWidth">
				Descarca aplicatie
			</el-button>
			<div class="separator"></div>
			<el-tabs type="border-card" class="tabs" v-model="activeName">
				<el-tab-pane label="Intro" name="intro">
					<p>Compatibilitate: Prestashop 1.6 & 1.7</p>
					<p>Se va descarca aplicatia de la acest <a style="color: #00a3d4"
							href="http://api-mp.cel.ro/market_cdn/exporter/latest.zip" target="_blank">URL</a>.</p>
					<p>Salvati aplicatia in directorul principal din magazinul dumneavoastra pentru a putea fi accesat
						din browser.</p>
					<p>Realizati un backup al bazei de date</p>
					<p>Accesati URL-ul https://site/nume_folder/public</p>
					<p>Urmati pasii pentru setari</p>
				</el-tab-pane>
				<el-tab-pane label="Setari" name="settings">
					<p>Completati toate setarile pentru a va putea utiliza aplicatia</p>
					<p>Primul pas este reprezentat de conectarea cu numele de utilizator si parola la API-ul Cel
						Marketplace. Acestea reprezinta numele si parola din contul de Cel Marketplace</p>
					<p>Selectati mai intai optiunea DEMO pentru a va familiariza cu aplicatia, iar Live dupa ce ati
						testat aplicatia</p>
					<p>Selectati platforma pe care o utilizati</p>
					<p>Realizati conexiunea la baza de date si verificati corectitudinea datelor</p>
					<p>Finalizati setup-ul si instalati tabelele in baza de date</p>
					<p>Dupa ce ati finalizat setup-ul, accesati https://site/nume_folder/public/init pentru a genera un
						username si parola pentru aplicatie</p>
					<p>Accesati din nou https://site/nume_folder/public/#login pentru a va conecta la aplicatie</p>
				</el-tab-pane>
				<el-tab-pane label="Mapeaza categorii si caracteristici" name="mapcat">
					<p>Este necesara maparea categoriilor din magazinul dumneavoastra cu cel Marketplace</p>
					<p>Mapati apoi caracteristicile din magazinul dumneavoastra cu cele din Cel Marketplace</p>
					<p>Nu uitati sa salvati modificarile</p>
				</el-tab-pane>
				<el-tab-pane label="Sincronizare produse" name="products">
					<p>Pentru a sincroniza produsele dati click pe butonul "Sync"</p>
					<p>Doar produsele mapate corect se vor sincroniza</p>
				</el-tab-pane>
			</el-tabs>
		</dd-card>
	</div>
</template>

<style scoped>
	p {
		font-size: 15px
	}
</style>

<script>
	const DDCard = () => import('@/components/DDCard');
	const Box = () => import('vue-info-box-element');

	export default {
		data() {
			return {
				activeName: 'intro',
			};
		},
		components: {
			'dd-card': DDCard,
			Box,
		},
		methods: {
			downloadApp() {
				window.open('http://api-mp.cel.ro/market_cdn/exporter/latest.zip', '_blank');
			}
		}
	};
</script>